// Override default variables before the import
// $bg-secondary: pink;
// $primary: hsl(245, 41%, 21%);
$primary: hsl(216, 80%, 39%);
$primary-bg: hsl(216, 100%, 85%);
$secondary: hsl(45, 85%, 50%);
$secondary-bg: hsl(45, 100%, 91%);
$white: #fff;
$black: #222;
$linear-gradient-rainbow: linear-gradient(-240deg,#bca2f6,#cdb9f8 5%,#b3e9ff 17%,#b3e9ff 25%,#bffcf7 35%,#ffe366 60%,#fad1d1 85%);
$warning: $secondary;
$gray-200: #e9ecef;
$gray-600: #6c757d;
$light: $gray-200;
$dark: #111111;
$accordion-button-color: $secondary;
$accordion-button-active-bg: tint-color($secondary, 90%);
// $accordion-button-active-color: shade-color($secondary, 10%);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200;300;400;500;600;700&family=Pacifico&display=swap&display=swap');
/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
}

* {
  font-display: swap;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

// .nav-link {
//   padding-left: 1.5rem;
//   padding-right: 1.5rem;

//   &:hover {
//     box-shadow: 1px 0 2px rgba(0,0,0,0.25)/*, -2px -2px 5px rgba(0,0,0,0.25)*/;
//   }
// }

// .nav-link[aria-current="page"] {
//   background-color: #fff;
//   color: #345;
//   border-color: rgba(0, 0, 0, 0.5);
//   border-width: 1px;
// }
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Oswald, sans-serif;
  letter-spacing: 1px;
  font-weight: 800;
}

.font-oswald {
  font-family: Oswald, sans-serif;
}

.font-open-sans {
  font-family: "Open Sans", sans-serif;
}

.font-pacifico {
  font-family: Pacifico, script;
}

/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-border {border: 0;}

.footer {
  display: block;
  padding: 2rem 0;
  margin-top: 3.5rem;
}
.rainbow-box-shadow {
  box-shadow: 0 3px #47ebdd, -3px 0 #fff833, 3px 0 #7171ef, 0 -3px 0 #f1818a;
  margin: 0 1.25rem 0 0;
}
.rainbow-text {
  background-clip: text;
  color: transparent;
  background-image: radial-gradient(ellipse at bottom right,#b698f5 30%,#66d4ff 40%,#5df8eb 50%,#ffec99 60%,#f28c8c 75%);
}

nav .btn {
  font-weight: 900;
  background-color: transparent;
}

.weather-cards-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.weather-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.weather-card img {
  max-width: 65px;
  height: 65px;
  margin: 1rem;
}

.split-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.split-row > div {
  flex-basis: 50%;
}